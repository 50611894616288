/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from "react";
import Grid from "@material-ui/core/Grid";
import { Button, Typography } from "@material-ui/core";
import { Translate } from 'utils/Translate';
import Workout from "hoc/WorkoutContainer"
import Routes from 'components/Router/Routes';
import InfiniteScroll from "react-infinite-scroller";
import LoadingClassesShimmer from "components/Shimmer/LoadingClassesShimmer";
import { LAZY_LOADING_PAGE_SIZE } from "utils/constants";
import { useChannelView } from "hooks/useChannelView";
import { getPosterSize, appendQueryParameterInURL } from "utils/utilityfunctions";
import { useLocation, useNavigate, useParams } from "react-router";
import BannerComponent from "components/Banner/BannerComponent";
import CommonDrawer from "components/Drawers/CommonDrawer";
import Filter from "containers/LiveConnect/filter";
import config from "../../assets/config.json"
import {logChannelViewed} from "utils/Localytics";

const ChannelView = (props) => {
    //window.scrollTo(0, 0);
    const navigate = useNavigate();
    const {pathname : path,state } = useLocation();
    const previousPageData = state ? state.clubClasses : null;
    const { useChannelViewState, useChannelViewDispatch,channelReducer } = useChannelView()

    const { channelsTiltes, isClassesLoading, isCalled, channels, searchOnDemandMetadata, filterSearchText,
        topKeywordsChannel, filteredTopKeywords, filteredKeywords, tempClassSearchDataChannel, selectedFilters,
        paginatedData ,pageCount,filterSortBy,filteredEquipments, filteredDuration,filteredFocusArea,filteredIntensity,
        filteredLanguage, filteredMediaType, filteredSkill, filteredSubCategory, tempFilterSearchClasses
    } = useChannelViewState()

    const { clearAllChannelClasses, saveDeeplinkPayload, fetchAllChannels,
        fetchChannelClasses, setChannelPaginatedData, setPageCount, resetChannelFilters, setChannelTopKeywords
        ,setChannelKeywords , setTempClassSearchChannelData, setFilteredTopKeywords, setFilteredClassSearchData, setSelectedFiltersChannel, searchOnDemandClassWithFilter
    } = useChannelViewDispatch()

    const { setReducerData,useChannelReducer,initialState} = channelReducer;
    const [channelState, channelDispatch] = useReducer(useChannelReducer, initialState);
    const { channelsData, titlesToDispay, hasMoreData, showNoDataFound, classesCount } = channelState;
    const params = useParams();
    
    useEffect(()=> {
        if (!channels) {
            fetchAllChannels()
        }
        return () => clearAllChannelClasses()
    },[])

    useEffect(() => {
        const id = params?.id
        const channelDetails = channels?.filter((channel)=> id === channel.tag)
        channelDetails && setReducerData(channelDispatch, { channelsData: channelDetails[0] })
        resetChannelFilters()
        setReducerData(channelDispatch, { showNoDataFound: false })
        isCalled && fetchData(params?.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.id])

    const fetchData = (channelTag, keyword = '') => {
        let selectedFilters, filterSearchText
        if (selectedFilters?.length > 0 || filterSearchText?.length > 0)
            fetchDataByFilters(selectedFilters, true)
        else {
            const providerId = channelsData?.provider?.tag
            let data = {
                from: "channels", searchTerm:channelTag, keyword, providerId
            }
            fetchChannelClasses(data)
        }
    }

    useEffect(() => {
        const id = params.id
        if (channelsData?.length === 0 || channelsData == undefined) {
            const channelDetails = channels?.filter((channel) => id === channel.tag)
            channelDetails && setReducerData(channelDispatch, { channelsData: channelDetails[0] })
        }
    }, [channels])

    useEffect(() => {
        if (selectedFilters.length === 0)
            setChannelTopKeywords(searchOnDemandMetadata && searchOnDemandMetadata.topLabels)
        
        if (tempClassSearchDataChannel?.length === 0 && selectedFilters?.length === 0 && filterSearchText?.length === 0 && channelsTiltes?.length > 0 && pageCount === 0) {
            setChannelPaginatedData(channelsTiltes.slice(0, LAZY_LOADING_PAGE_SIZE))
        }
        else if ((filterSearchText?.length > 0 || selectedFilters?.length > 0)) {
            setChannelPaginatedData(tempClassSearchDataChannel.slice(0, LAZY_LOADING_PAGE_SIZE))
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channelsTiltes, tempClassSearchDataChannel])

    const resetAllFiltersValues = () => {
        setTempClassSearchChannelData([])
        setReducerData(channelDispatch, { classesCount: (channelsTiltes.length) })
        resetChannelFilters()
        if (channelsTiltes.length > 0)
            setReducerData(channelDispatch, { showNoDataFound: false })

    }

    const fetchDataByFilters = (filters, fromNavigation = false, reset = false, fromTopKeyWords = false) => {
        setPageCount(0)
        setChannelPaginatedData([])
        if (reset === true || (filterSearchText.length === 0 && filters.length === 0)) {
            resetAllFiltersValues()
        }
        else if (filterSearchText.length > 0) {
            fetchDataByFilterSearch(filterSearchText)
        }
        else {
            if (fromNavigation)
                getFilteredData(filteredClassSearchData, filters, fromTopKeyWords)
            else
                getFilteredData(channelsTiltes, filters, fromTopKeyWords)
        }
    }

    const getFilteredData = (channelsTitles, filters, fromTopKeyWords) => {
        let tempData = [],
            skillLevels = [],
            intensityLevels = [],
            equipment = "",
            keyword = "",
            focusArea = "",
            classLanguage = "",
            sortBy = "",
            subCategory = "",
            duration = "",
            mediaType = ''
        for (let j = 0; j < filters.length; j++) {
            switch (filters[j].filterType.toString().toLowerCase()) {
                case `keywords`:
                    keyword = filters[j].filterValue;
                    break;
                case `duration`:
                    duration = filters[j].filterValue;
                    break;
                case `skill level`:
                    skillLevels = filters[j].filterValue.split(",");
                    break;
                case `focus area`:
                    focusArea = filters[j].filterValue;
                    break;
                case `equipment`:
                    equipment = filters[j].filterValue;
                    break;
                case `intensity`:
                    intensityLevels = filters[j].filterValue.split(",");
                    break;
                case `class language`:
                    classLanguage = filters[j].filterValue;
                    break;
                case `sub category`:
                    subCategory = filters[j].filterValue;
                    break;
                case `sort`:
                    sortBy = filters[j].filterValue;
                    break;
                case `media type`:
                    mediaType = filters[j].filterValue;
                    break;
                default:
                    return null;
            }
        }


        for (let i = 0; i < channelsTitles.length; i++) {
            let allow = true, found = true
            //Keywords
            if (keyword.length > 0 && channelsTitles[i].virtualClass.labels && channelsTitles[i].virtualClass.labels.length > 0) {
                for (let index = 0; index < channelsTitles[i].virtualClass.labels.length; index++) {
                    if (!(channelsTitles[i].virtualClass.labels[index].includes(keyword))) {
                        found = false
                    }
                    else {
                        found = true
                        break;
                    }
                }
                if (!found)
                    allow = false
            }
            else if (keyword.length > 0 && channelsTitles[i].virtualClass.labels && channelsTitles[i].virtualClass.labels.length === 0) {
                allow = false
            }
            else if (keyword.length > 0 && !channelsTitles[i].virtualClass.labels) {
                allow = false
            }
            //Duration
            if (duration.length > 0 && channelsTitles[i].virtualClass.durationSecond) {
                let timeMin, timeMax;
                switch (duration) {
                    case '0,600':  //10 min
                        timeMin = 0; timeMax = 600
                        break;
                    case '601,1200':  //20 min
                        timeMin = 601; timeMax = 1200
                        break;
                    case '1201,1800':   //30 min
                        timeMin = 1201; timeMax = 1800
                        break;
                    case '1801,18000':    //40+min
                        timeMin = 1801; timeMax = 18000
                        break;
                    default:
                        return null
                }
                if (!(parseFloat(channelsTitles[i].virtualClass.durationSecond) >= parseInt(timeMin) && parseFloat(channelsTitles[i].virtualClass.durationSecond) <= (timeMax))) {
                    allow = false
                }
            }
            else if (duration.length > 0 && !channelsTitles[i].virtualClass.durationSecond) {
                allow = false
            }
            //Skill Levels
            if (skillLevels.length > 0 && channelsTitles[i].virtualClass.skill !== undefined) {
                if (!(channelsTitles[i].virtualClass.skill >= skillLevels[0] && channelsTitles[i].virtualClass.skill <= skillLevels[1])) {
                    allow = false
                }
            }
            else if (skillLevels.length > 0 && channelsTitles[i].virtualClass.skill === undefined) {
                allow = false
            }
            //Intensity levels
            if (intensityLevels.length > 0 && channelsTitles[i].virtualClass.intensity !== undefined) {
                if (!(channelsTitles[i].virtualClass.intensity >= intensityLevels[0] && channelsTitles[i].virtualClass.intensity <= intensityLevels[1])) {
                    allow = false
                }
            }
            else if (intensityLevels.length > 0 && channelsTitles[i].virtualClass.intensity === undefined) {
                allow = false
            }
            //Class SubCategory
            if (subCategory.length > 0 && channelsTitles[i].virtualClass.classSubCategory) {
                if (!(channelsTitles[i].virtualClass.classSubCategory.toLowerCase() === subCategory.toLowerCase())) {
                    allow = false
                }
            }
            else if (subCategory.length > 0 && !channelsTitles[i].virtualClass.classSubCategory) {
                allow = false
            }
            //Class Language
            if (classLanguage.length > 0 && channelsTitles[i].virtualClass.languageName) {
                if (!(channelsTitles[i].virtualClass.languageName.toLowerCase() === classLanguage.toLowerCase())) {
                    allow = false
                }
            }
            else if (classLanguage.length > 0 && !channelsTitles[i].virtualClass.languageName) {
                allow = false
            }
            //FocusArea
            if (focusArea.length > 0 && channelsTitles[i].virtualClass.focusArea && channelsTitles[i].virtualClass.focusArea.length > 0) {
                for (let index = 0; index < channelsTitles[i].virtualClass.focusArea.length; index++) {
                    if (!(channelsTitles[i].virtualClass.focusArea[index].includes(focusArea))) {
                        found = false
                    }
                    else {
                        found = true
                        break;
                    }
                }
                if (!found)
                    allow = false
            }
            else if (focusArea.length > 0 && channelsTitles[i].virtualClass.focusArea && channelsTitles[i].virtualClass.focusArea.length === 0) {
                allow = false
            }
            else if (focusArea.length > 0 && !channelsTitles[i].virtualClass.focusArea) {
                allow = false
            }
            //Equipments
            if (equipment.length > 0 && channelsTitles[i].virtualClass.equipmentNames && channelsTitles[i].virtualClass.equipmentNames.length > 0) {
                for (let index = 0; index < channelsTitles[i].virtualClass.equipmentNames.length; index++) {
                    if (!(channelsTitles[i].virtualClass.equipmentNames[index].includes(equipment))) {
                        found = false
                    }
                    else {
                        found = true
                        break;
                    }
                }
                if (!found)
                    allow = false
            }
            else if (equipment.length > 0 && channelsTitles[i].virtualClass.equipmentNames && channelsTitles[i].virtualClass.equipmentNames.length === 0) {
                allow = false
            }
            else if (equipment.length > 0 && !channelsTitles[i].virtualClass.equipmentNames) {
                allow = false
            }
            if (mediaType !== "") {
                if (!(channelsTitles[i].virtualClass.mediaType == mediaType)
                ) {
                    allow = false;
                }
            }
            if (allow)
                tempData.push(channelsTitles[i])
        }
        !fromTopKeyWords && fetchTopKeywords(tempData)
        setReducerData(channelDispatch, { classesCount: (tempData.length) })
        if (sortBy.length > 0) {
            let sortedData = tempData.sort((a, b) => {
                if (sortBy === "Newest")
                    return new Date(b.virtualClass.scheduleDate) - new Date(a.virtualClass.scheduleDate)
                else
                    return new Date(a.virtualClass.scheduleDate) - new Date(b.virtualClass.scheduleDate)
            });
            setTempClassSearchChannelData(sortedData)
        }
        else
            setTempClassSearchChannelData(tempData)

        if (tempData.length === 0)
            setReducerData(channelDispatch, { showNoDataFound: true })

        else
            setReducerData(channelDispatch, { showNoDataFound: false })

        setFilteredClassSearchData(tempData)
        window.scrollTo(0, 0);

    }

    const fetchTopKeywords = (channelsTitles) => {

        let tempTopKeywords = []
        for (let i = 0; i < channelsTitles?.length; i++) {
            if (tempTopKeywords.length === 0 && channelsTitles[i].virtualClass.labels.length === 1) {
                tempTopKeywords.push({ keyword: channelsTitles[i].virtualClass.labels[0], count: 1 })
            }
            else {
                if (channelsTitles[i].virtualClass.labels.length === 1) {
                    let temp = [...tempTopKeywords], matched = false
                    for (let x = 0; x < temp.length; x++) {
                        if (tempTopKeywords[x].keyword === channelsTitles[i].virtualClass.labels[0]) {
                            tempTopKeywords[x] = { ...tempTopKeywords[x], count: parseInt(tempTopKeywords[x].count) + 1 }
                            matched = true
                            break;
                        }
                    }
                    if (!matched) {
                        tempTopKeywords.push({ keyword: channelsTitles[i].virtualClass.labels[0], count: 1 })
                    }
                }
                else {
                    let temp = [...tempTopKeywords], matched = false
                    for (let j = 0; j < channelsTitles[i].virtualClass.labels.length; j++) {
                        matched = false
                        for (let y = 0; y < temp.length; y++) {
                            if (tempTopKeywords[y].keyword === channelsTitles[i].virtualClass.labels[j]) {
                                tempTopKeywords[y] = { ...tempTopKeywords[y], count: parseInt(tempTopKeywords[y].count) + 1 }
                                matched = true
                                break;
                            }
                        }
                        if (!matched) {
                            tempTopKeywords.push({ keyword: channelsTitles[i].virtualClass.labels[j], count: 1 })
                        }
                    }
                }
            }
        }

        let sortTopKeywords = tempTopKeywords.sort(function (a, b) {
            return b.count - a.count
        })
        let sortedTopKeywords = []
        for (let z = 0; z < sortTopKeywords.length; z++) {
            sortedTopKeywords.push(sortTopKeywords[z].keyword)
        }
        if (sortedTopKeywords.length > 3)
            sortedTopKeywords = sortedTopKeywords.splice(0, 3)

        let temp = [...topKeywordsChannel]
        let prevTopKeywords = filteredTopKeywords.length > 0 ? filteredTopKeywords : temp.splice(0, 3)
        let tempsortedTopKeywords = [...sortedTopKeywords]
        let tempprevTopKeywords = [...prevTopKeywords]
        let sortedTKeywordsByWord = tempsortedTopKeywords.sort()
        let sortedPrevTopKeywordsByWord = tempprevTopKeywords.sort()

        if (JSON.stringify(sortedTKeywordsByWord) !== JSON.stringify(sortedPrevTopKeywordsByWord)) {
            setFilteredTopKeywords(sortedTopKeywords)
        }
    }

    const fetchDataByTopKeywords = (filterValue, filterType = 'Keywords', formTopKeyWords = false) => {
        setPageCount(0)
        setChannelPaginatedData([])
        let tempFilters = [...selectedFilters], sameValueSelected = false
        if (selectedFilters.length === 0) {
            tempFilters.push({ filterType, filterValue })
            setChannelKeywords(filterValue)
        }
        else {
            for (let index = 0; index < selectedFilters.length; index++) {
                if (selectedFilters[index].filterType === filterType) {
                    if (selectedFilters[index].filterValue === filterValue)
                        sameValueSelected = true
                    tempFilters.splice(index, 1);
                }
            }
            if (!sameValueSelected) {
                tempFilters.push({ filterType, filterValue })
                setChannelKeywords(filterValue)
            }
            else {
                setChannelKeywords('')
            }
        }
        setSelectedFiltersChannel(tempFilters)
        fetchDataByFilters(tempFilters, false, false, formTopKeyWords)
    }
    
    const onCardClick = (titleDetail) => {
        let channelPayload = {}
        if (previousPageData) {
            channelPayload = previousPageData;
        }
        else {
            channelPayload = {
                ...channelsData
            }
        }
        let data = {
            ...channelPayload,
            title: [titleDetail]
        }
        navigate(Routes.onDemandClassPlayerRoute + titleDetail.tag,
            {state: { selectedItem: data, from: 'CHANNEL' }} );
    }

    const getChannelDescription = (channelData) => {
        if (channelData == null) {
            return null
        }
        let channelDescription = channelData.description.invariantText
        if (channelDescription == null) {
            channelDescription = channelData.description
        }
        return channelDescription;
    }
    const getChannelDisplayName = (channelData) => {
        if (channelData == null) {
            return null
        }
        let channelDisplayName = channelData.displayName.invariantText
        if (channelDisplayName == null) {
            channelDisplayName = channelData.displayName
        }
        return channelDisplayName?channelDisplayName:channelData?.name;
    }
    const checkFavStatus = (selectedClassTag) => {
        let classes = titlesToDispay;
        let classIndex = classes.items.findIndex(particularclass => particularclass.tag === selectedClassTag)
        if (classIndex !== -1) {
            classes.items[classIndex].favourite = !classes.items[classIndex].favourite
        }
    }

    const handleChannelChange = (id) => {
        if (id.toString() !== channelsData?.tag) {
            fetchData(id)
            setChannelPaginatedData([])
            resetChannelFilters()
            navigate(`${Routes.channelDetailView}${id}`)
        }
    }

    useEffect(() => {
        if ((tempClassSearchDataChannel?.length > 0 || channelsTiltes?.length > 0) && paginatedData?.length > 0) {
            let allClasses = tempClassSearchDataChannel?.length > 0 ? tempClassSearchDataChannel : channelsTiltes
            if (allClasses.length <= paginatedData.length) {
                setReducerData(channelDispatch, { hasMoreData: false })

            }
            else {
                setReducerData(channelDispatch, { hasMoreData: true })

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginatedData])

    useEffect(() => {
        if (tempFilterSearchClasses && filterSearchText.length > 0) {
            setReducerData(channelDispatch, { classesCount: (tempFilterSearchClasses?.length) })
            setTempClassSearchChannelData(tempFilterSearchClasses)
            fetchTopKeywords(tempFilterSearchClasses)
            if (tempFilterSearchClasses.length === 0)
                setReducerData(channelDispatch, { showNoDataFound: true })
            else
                setReducerData(channelDispatch, { showNoDataFound: false })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempFilterSearchClasses])

    const loadMore = () => {
        let toFilterClasses = []
        if (tempClassSearchDataChannel.length === 0 && selectedFilters.length === 0 && filterSearchText?.length === 0 ) {
            toFilterClasses = channelsTiltes
        }
        else
            toFilterClasses = tempClassSearchDataChannel
        setTimeout(() => {
                if (pageCount < toFilterClasses.length) {
                 let data = toFilterClasses.slice(
                        0,
                        pageCount + LAZY_LOADING_PAGE_SIZE
                    );
                    if (toFilterClasses.length <= data.length) {
                        setPageCount(pageCount + LAZY_LOADING_PAGE_SIZE)
                        setReducerData(channelDispatch, { hasMoreData: false})
                        setChannelPaginatedData(data)
                    } else {
                        setPageCount(pageCount + LAZY_LOADING_PAGE_SIZE)
                        setChannelPaginatedData(data)
                    }
                } else {
                    setReducerData(channelDispatch, { hasMoreData: false})
                }
            }, 1000);
    };

    const menuHeading = {
        "title": "ChannelsTopNav",
        "context": "navigation"
    }

    const fetchDataByFilterSearch = (searchText, fromSearch = true) => {
        const channelTag = params?.id
        searchOnDemandClassWithFilter(channelTag,filteredKeywords, filteredFocusArea, filteredSkill, filteredDuration, filteredEquipments, filteredIntensity, searchText, filterSortBy, filteredLanguage, filteredSubCategory, fromSearch, config.providerId, filteredMediaType)
    }

    const drawerData = () => {
       return channels?.map((channel)=> { 
        return { tag : channel.tag,
                name: channel.displayName,
        }}
       ) 
    }

    return (
        <div className="category-page align-left">

                <BannerComponent
                heading= {getChannelDisplayName(channelsData)}
                subHeading= {getChannelDescription(channelsData)}
                imageUrl={channelsData &&  appendQueryParameterInURL(channelsData.mediaSpaceImageUrl,"width",getPosterSize().channelBanner)}
                titleImage={channelsData && channelsData?.profileImageUrl}
                menuHeading={menuHeading}
                bannerHeadingClasses={"channel-h1"}
                bannerContainerClasses={"channel-img-container"}
                id={channelsData?.tag}
                drawer={<CommonDrawer
                    menuHeading={menuHeading}
                    data={drawerData()}
                    handleClick={handleChannelChange}
                />}
                ></BannerComponent>

            <div className="detail-page">     
                <div className="main-page-container list-container-gap">
                    <Grid container justifyContent="flex-start" className="m-t-xs-15 topKeySpace">
                        <Filter
                            showFLLOnDemand={false}
                            showChannelFilter={true}
                            categoryName={''}
                            topKeywords={showNoDataFound ? [] : filteredTopKeywords.length > 0 ? filteredTopKeywords : topKeywordsChannel}
                            initialFilterMetaData={searchOnDemandMetadata}
                            fetchDataByFilters={fetchDataByFilters}
                            classesCount={classesCount}
                            fetchDataByTopKeywords={fetchDataByTopKeywords}
                            fetchDataByFilterSearch={fetchDataByFilterSearch}
                        />
                    </Grid>
                    {!isClassesLoading ?
                        <InfiniteScroll
                            pageStart={0}
                            datalength={LAZY_LOADING_PAGE_SIZE}
                            loadMore={loadMore}
                            hasMore={hasMoreData}
                            loader={hasMoreData ? <LoadingClassesShimmer key={Math.random()}
                                myClass="live-stream-loader"
                                totalCards={4}
                            /> : null} >
                            <div className='list list-live-page m-bt-lg-30 m-t-xs-20'> 
                            {paginatedData?.length > 0 && paginatedData.map((item, index) => (
                                <Workout
                                    cardType="channel-page-card"
                                    collectionType="featured"
                                    isSearchPageCard={false}
                                    displayDate={item.virtualClass.scheduleDate}
                                    trailerLinkWeb={item.virtualClass.trailerLinkWeb}
                                    trailerLinkMobile={item.virtualClass.trailerLinkMobile}
                                    isSliderWorkout={true}
                                    history={props.history}
                                    // showFav={isAuthenticated ? true : false}
                                    thumbnail={`${item.virtualClass.imageLink}`}
                                    equipmentTypes={item.virtualClass.equipmentTypes}
                                    equipmentNames={item.virtualClass.equipmentNames}
                                    isFav={item.virtualClass.favourite}
                                    title={item.virtualClass.className}
                                    duration={item.virtualClass.durationSecond
                                        ? Math.ceil(item.virtualClass.durationSecond / 60)
                                        : 55}
                                    description={item.virtualClass.classDescription}
                                    show={true}
                                    calories={item.virtualClass.calorieBurn}
                                    id={item.virtualClass.tag}
                                    classCategory={item.virtualClass.classCategory}
                                    mediaType={item.virtualClass.mediaType}
                                    key={index}
                                    clicked={() => onCardClick(item.virtualClass)}
                                    level={item?.virtualClass?.level ? item.virtualClass.level : item?.level}
                                    favCallback={() => { checkFavStatus(item.virtualClass.tag) }}
                                    isUnlocked={item.virtualClass.isUnlocked}
                                />
                            ))} 
                            </div>
                        </InfiniteScroll> : <LoadingClassesShimmer />
                        }
                    {
                        showNoDataFound && <Grid container justifyContent="flex-start" spacing="4" >
                            <Typography className="text-gray padding16 m-t-55 m-t-xs-32"
                                style={{ textAlign: 'center', width: '100%' }}
                            >
                                {Translate({ id: "filter.NoClassesFound" })}
                            </Typography>
                            <div className="padding16 m-t-xs-15 m-t-30" style={{ textAlign: 'center', width: '100%' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    className="button m-t-15 btn-default"
                                    style={{ height: 48, borderRadius: 35, }}
                                    onClick={resetAllFiltersValues}
                                >
                                    <Typography variant="button" style={{ color: "secondary" }}>
                                        {Translate({ id: "filter.ShowAllClasses" })}
                                    </Typography>
                                </Button>
                            </div>
                        </Grid>
                    }
                </div>
            
        </div >  </div>)
}
export default ChannelView